
  import { Component, Watch } from 'vue-property-decorator'
  import { GForm } from '@/components/forms/GForm'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { fixPrice, stringifySafe } from '@/utils/general'
  import LinkedAuto from '@/components/forms/fields/LinkedAuto.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import IdentifierPanel from '@/components/forms/inspection/panels/IdentifierPanel.vue'
  import { plainToInstance } from 'class-transformer'
  import { Inspection } from '@/entities/purchase'
  import InspectionQualification from '@/components/forms/inspection/inspectionQualification.vue'
  import InspectionNotQualified from '@/components/forms/inspection/inspectionNotQualified.vue'
  import MaintenancePanel from '@/components/forms/inspection/panels/MaintenancePanel.vue'
  import DocumentationPanel from '@/components/forms/inspection/panels/DocumentationPanel.vue'
  import BodyWorkPanel from '@/components/forms/inspection/panels/BodyWorkPanel.vue'
  import RearviewMirrorPanel from '@/components/forms/inspection/panels/RearviewMirrorPanel.vue'
  import TireAndWheelPanel from '@/components/forms/inspection/panels/TireAndWheelPanel.vue'
  import GeneralLoading from '@/components/core/GeneralLoading.vue'
  import GLoading from '@/components/core/GLoading.vue'
  import OpticPanel from '@/components/forms/inspection/panels/OpticPanel.vue'
  import InsidePanel from '@/components/forms/inspection/panels/InsidePanel.vue'
  import MechanicalPanel from '@/components/forms/inspection/panels/MechanicalPanel.vue'
  import ElectricPanel from '@/components/forms/inspection/panels/ElectricPanel.vue'
  import EquipmentPanel from '@/components/forms/inspection/panels/EquipmentPanel.vue'
  import ComfortEquipmentPanel from '@/components/forms/inspection/panels/ComfortEquipmentPanel.vue'
  import { Debounce } from '@/utils/decorators'
  import { mapActions, mapGetters } from 'vuex'
  import { ComponentCategory, Generation } from '@/entities/vehicle'
  import SecurityEquipmentPanel from '@/components/forms/inspection/panels/SecurityEquipmentPanel.vue'
  import ExteriorEquipmentPanel from '@/components/forms/inspection/panels/ExteriorEquipmentPanel.vue'
  import PhotosPanel from '@/components/forms/inspection/panels/PhotosPanel.vue'
  import _ from 'lodash'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import IdentifierSupervisorPanel from '@/components/forms/inspection/supervisor/panel/IdentifierSupervisorPanel.vue'
  import DocumentationSupervisorPanel
    from '@/components/forms/inspection/supervisor/panel/DocumentationSupervisorPanel.vue'
  import MaintenancePanelSupervisorExpanded
    from '@/components/forms/inspection/supervisor/components/MaintenancePanelSupervisorExpanded.vue'
  import MaintenanceSupervisorPanel from '@/components/forms/inspection/supervisor/panel/MaintenanceSupervisorPanel.vue'
  import BodyWorkSupervisorPanel from '@/components/forms/inspection/supervisor/panel/BodyWorkSupervisorPanel.vue'
  import OpticSupervisorPanel from '@/components/forms/inspection/supervisor/panel/OpticSupervisorPanel.vue'
  import RearviewMirrorSupervisorPanel
    from '@/components/forms/inspection/supervisor/panel/RearviewMirrorSupervisorPanel.vue'
  import TireAndWheelSupervisorPanel from '@/components/forms/inspection/supervisor/panel/TireAndWheelSupervisorPanel.vue'
  import InsideSupervisorPanel from '@/components/forms/inspection/supervisor/panel/InsideSupervisorPanel.vue'
  import ElectricSupervisorPanel from '@/components/forms/inspection/supervisor/panel/ElectricSupervisorPanel.vue'
  import MechanicalSupervisorPanel from '@/components/forms/inspection/supervisor/panel/MechanicalSupervisorPanel.vue'
  import EquipmentSupervisorPanel from '@/components/forms/inspection/supervisor/panel/EquipmentSupervisorPanel.vue'
  import ComfortEquipmentSupervisorPanel
    from '@/components/forms/inspection/supervisor/panel/ComfortEquipmentSupervisorPanel.vue'
  import SecurityEquipmentSupervisorPanel
    from '@/components/forms/inspection/supervisor/panel/SecurityEquipmentSupervisorPanel.vue'
  import ExteriorEquipmentSupervisorPanel
    from '@/components/forms/inspection/supervisor/panel/ExteriorEquipmentSupervisorPanel.vue'
  import PhotosSupervisorPanel from '@/components/forms/inspection/supervisor/panel/PhotosSupervisorPanel.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import ButtonCard from '@/components/forms/inspection/cards/ButtonCard.vue'
  import InspectionComment from '@/components/forms/inspection/inspectionComment.vue'

@Component({
  components: {
    InspectionComment,
    ButtonCard,
    RatingCell,
    PhotosSupervisorPanel,
    ExteriorEquipmentSupervisorPanel,
    SecurityEquipmentSupervisorPanel,
    ComfortEquipmentSupervisorPanel,
    EquipmentSupervisorPanel,
    MechanicalSupervisorPanel,
    ElectricSupervisorPanel,
    InsideSupervisorPanel,
    TireAndWheelSupervisorPanel,
    RearviewMirrorSupervisorPanel,
    OpticSupervisorPanel,
    BodyWorkSupervisorPanel,
    MaintenanceSupervisorPanel,
    MaintenancePanelSupervisorExpanded,
    DocumentationSupervisorPanel,
    IdentifierSupervisorPanel,
    GAlert,
    PhotosPanel,
    ExteriorEquipmentPanel,
    SecurityEquipmentPanel,
    ComfortEquipmentPanel,
    EquipmentPanel,
    ElectricPanel,
    MechanicalPanel,
    InsidePanel,
    OpticPanel,
    GLoading,
    GeneralLoading,
    TireAndWheelPanel,
    RearviewMirrorPanel,
    BodyWorkPanel,
    DocumentationPanel,
    MaintenancePanel,
    InspectionNotQualified,
    InspectionQualification,
    IdentifierPanel,
    GFiles,
    LinkedAuto,
    BaseForm,
  },
  methods: {
    ...mapActions('resources/form', ['getCategoryScore']),
    fixPrice,
  },
  computed: {
    ...mapGetters('resources', ['categories']),
  },
})
  export default class InspectionView extends GForm {
  declare $refs: {
    form: HTMLFormElement,
    basePanel: DocumentationPanel
  };

  getCategoryScore!: ({ categoryName, idInspection }: { categoryName: string, idInspection: number }) => Promise<number>

  totalSteps = 15
  categories!: Array<ComponentCategory>
  rating = 0
  loading = true
  generation = null
  overCategory = null
  overScore = null

  formData = {
    categoryName: null,
    category: null,
    total: null,
    score: null,
  }

  overAlert = {
    open: false,
    title: `La inspección cambiara a "En progreso" para que el inspector pueda finalizarla`,
  }

  negotiationAlert = {
    open: false,
    title: 'El monto de la negociación no puede ser negativo',
  }

  category = {
    identifier: false,
    documentation: false,
    maintenance: false,
    bodywork: false,
    spotlightOptic: false,
    rearviewMirror: false,
    tireAndWheel: false,
    inside: false,
    electric: false,
    mechanical: false,
    equipment: false,
    comfortEquipment: false,
    securityEquipment: false,
    externalEquipment: false,
    photos: false,
  }

  form = {
    identifier: {
      panels: 0,
    },
    documentation: {
      panels: 0,
    },
    maintenance: {
      panels: 0,
    },
    bodywork: {
      panels: 0,
    },
    spotlightOptic: {
      panels: 0,
    },
    rearviewMirror: {
      panels: 0,
    },
    tireAndWheel: {
      panels: 0,
    },
    inside: {
      panels: 0,
    },
    electric: {
      panels: 0,
    },
    mechanical: {
      panels: 0,
    },
    equipment: {
      panels: 0,
    },
    comfortEquipment: {
      panels: 0,
    },
    securityEquipment: {
      panels: 0,
    },
    externalEquipment: {
      panels: 0,
    },
    photos: {
      panels: 0,
    },
  }

  componentCategories: Record<string, any> = {
    identifier: null,
    documentation: null,
    maintenance: null,
    bodywork: null,
    spotlightOptic: null,
    rearviewMirror: null,
    tireAndWheel: null,
    inside: null,
    electric: null,
    mechanical: null,
    equipment: null,
  }

  sendQualification = false
  finishCategories = false
  panels = -1
  title = 'Inspección'
  formChanged = false
  metadata = null
  idProcess = null
  idProcessInspection = null
  photoProperties = {
    properties: {
      accept: null,
      multiple: false,
      fileTypeId: null,
      name: '',
    },
  }

  categoryScore = {
    identifier: null,
    documentation: null,
    maintenance: null,
    bodywork: null,
    spotlightOptic: null,
    rearviewMirror: null,
    tireAndWheel: null,
    inside: null,
    electric: null,
    mechanical: null,
    equipment: null,
    comfortEquipment: null,
    securityEquipment: null,
    externalEquipment: null,
    photos: null,
  }

  categoryTarget = {
    Identificación: 'identifier',
    Documentación: 'documentation',
    Mantenimiento: 'maintenance',
    Carrocería: 'bodywork',
    Lunetas: 'rearviewMirror',
    'Focos y ópticos': 'spotlightOptic',
    Ruedas: 'tireAndWheel',
    Interior: 'inside',
    Eléctrica: 'electric',
    Mecánica: 'mechanical',
    Equipamiento: 'equipment',
    'Equipamiento comfort': 'comfortEquipment',
    'Equipamiento seguridad': 'securityEquipment',
    'Equipamiento exterior': 'externalEquipment',
    Fotos: 'photos',
  }

  categoryFixed = {
    Identificación: 'identifier',
    Documentación: 'documentation',
    Mantenimiento: 'maintenance',
    Carrocería: 'bodywork',
    Lunetas: 'rearviewMirror',
    'Focos y ópticos': 'spotlightOptic',
    Ruedas: 'tireAndWheel',
    Interior: 'inside',
    Eléctrica: 'electric',
    Mecánica: 'mechanical',
    'Equipamiento básico': 'equipment',
    'Equipamiento comfort': 'comfortEquipment',
    'Equipamiento seguridad': 'securityEquipment',
    'Equipamiento exterior': 'externalEquipment',
    Fotos: 'photos',
  }

  cards = {
    identifier: false,
    documentation: false,
    maintenance: false,
    bodywork: false,
    spotlightOptic: false,
    rearviewMirror: false,
    tireAndWheel: false,
    inside: false,
    electric: false,
    mechanical: false,
    equipment: false,
    comfortEquipment: false,
    securityEquipment: false,
    externalEquipment: false,
    photos: false,
  }

  maxMileageDifference = null
  minPercentageDifference = null
  categoryQualificationConfig = null

  inspection: Inspection = plainToInstance(Inspection, {})

  async mounted () {
    await this.setMetadata()
    const { uid, title, metadata } = this

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection' } },
    })

    this.overScore = process?.[0]?.config?.over
    this.categoryQualificationConfig = process?.[0]?.config?.categoryConfig
    this.maxMileageDifference = process?.[0]?.config?.maxMileageDifference
    this.minPercentageDifference = process?.[0]?.config?.minPercentageDifference

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadata, title, Boolean(uid))
    }

    if (!isNaN(uid)) {
      await this.getInfo(uid)
    }

    await this.setCategories()
  }

  async setCategories () {
    const identifier = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Identificación' } },
    })

    const bodywork = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Carrocería' } },
    })

    const rearviewMirror = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Lunetas' } },
    })

    const optic = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Focos y ópticos' } },
    })

    const tireAndWheel = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Ruedas' } },
    })

    const maintenance = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Mantenimiento' } },
    })

    const inside = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Interior' } },
    })

    const mechanical = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Mecánica' } },
    })

    const documentation = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Documentación' } },
    })

    const electric = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Eléctrica' } },
    })

    const equipment = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Equipamiento' } },
    })

    this.componentCategories = {
      identifier: identifier?.[0],
      bodywork: bodywork?.[0],
      rearviewMirror: rearviewMirror?.[0],
      spotlightOptic: optic?.[0],
      tireAndWheel: tireAndWheel?.[0],
      mechanical: mechanical?.[0],
      maintenance: maintenance?.[0],
      inside: inside?.[0],
      documentation: documentation?.[0],
      electric: electric?.[0],
      equipment: equipment?.[0],
    }

    this.loading = false
  }

  get change () {
    const { form, formData, loading, formChanged, inspection } = this

    return stringifySafe([form, formData, loading, formChanged, inspection])
  }

  async setMetadata () {
    const { metadata } = this.getForm('Inspection', 'supervisor_inspection')
    this.metadata = metadata

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection_inspected_component' } },
    })

    this.idProcess = process?.[0]?.id

    const processInspection = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection' } },
    })

    this.idProcessInspection = processInspection?.[0]?.id

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'inspection_inspected_component' } } },
    })

    if (fileInfo?.length) {
      this.photoProperties.properties = {
        accept: fileInfo[0].fileType.mimes,
        multiple: fileInfo[0].multiple,
        fileTypeId: fileInfo[0].fileType.id,
        name: fileInfo[0].name,
      }
    }
  }

  @Debounce()
  async getInfo (id) {
    const { isInspectionOver } = this
    if (isInspectionOver) return

    this.categoryScore = {
      identifier: await this.getCategoryScore({
        idInspection: id,
        categoryName: 'Identificación',
      }),
      documentation: null,
      maintenance: null,
      bodywork: await this.getCategoryScore({
        idInspection: id,
        categoryName: 'Carrocería',
      }),
      spotlightOptic: null,
      rearviewMirror: null,
      tireAndWheel: null,
      inside: null,
      electric: null,
      mechanical: await this.getCategoryScore({
        idInspection: id,
        categoryName: 'Mecánica',
      }),
      equipment: null,
      comfortEquipment: null,
      securityEquipment: null,
      externalEquipment: null,
      photos: null,
    }

    this.inspection = await this.fetchData({ query: { name: 'fetch', model: 'Inspection', params: { id } }, force: true })
    this.setBackup({ inspection: this.inspection })
    await this.viewInspectionStatus(this.inspection)
  }

  async categoryDone (val) {
    const { category } = this
    const total = Object.keys(category).length
    const keys = Object.keys(this.category)
    this.closeForms()
    this.setPanels(keys, val, total)
  }

  setPanels (keys, val, total) {
    const { finishCategories } = this

    if (val === total || finishCategories) {
      this.finishCategories = true

      this.category[keys[val]] = true
      return
    }

    if (val > 0 && this.category[keys[val - 1]]) {
      if (keys[val]) {
        this.category[keys[val]] = true
      }

      if (keys[val + 1] && this.form[keys[val + 1]]) {
        this.form[keys[val + 1]].panels = 0
      }
    } else if (val === 0) {
      this.category[keys[val]] = true
      if (keys[val + 1] && this.form[keys[val + 1]]) {
        this.form[keys[val + 1]].panels = 0
      }
    }
  }

  get done () {
    const { inspection } = this

    return inspection?.metadata?.validated || inspection?.supervisorMetadata?.supervisorAllowValidated
  }

  async send () {
    this.sendQualification = true
  }

  enableButton (val) {
    this.disableSend = !val
  }

  get isInspectionOver () {
    const { inspection } = this

    if (!inspection?.id) return false
    if (inspection?.status?.isClosed) this.panels = -1

    return Boolean(inspection?.closingReason?.notQualify)
  }

  get inspectionHasNegotiation () {
    const { inspection } = this

    return inspection?.inspectionHasNegotiation
  }

  get isInspectionClosed () {
    const { inspection } = this

    return Boolean(inspection?.closingReason)
  }

  @Watch('isInspectionClosed', { immediate: true })
  async onInspectionClose (val) {
    if (val) {
      const { inspection } = this
      const activity = await this.fetchData({
        query: { name: 'find', model: 'LeadActivity' },
        filter: {
          _and: [
            { id_lead: { _eq: inspection.deal.lead.id } },
            { activity_type: { name: { _eq: 'inspection' } } },
            { activity_result: { _is_null: true } },
          ],
        },
        force: true,
      })
      if (activity?.[0]?.id) {
        const close = await this.fetchData({
          query: { name: 'find', model: 'ProcessStatus' },
          filter: { _and: [{ process: { table_name: { _eq: 'lead_activity' } } }, { status: { name: { _eq: 'closed' } } }] },
          force: true,
        })
        await this.pushData({
          model: 'LeadActivity',
          fields: { id: activity[0].id, activity_result: true, id_process_status: close?.[0]?.id },
        })
      }
    }
  }

  get watchFormData () {
    const { formData, inspection } = this

    return {
      formData,
      inspection,
    }
  }

  @Watch('watchFormData', { immediate: true, deep: true })
  @Debounce(500)
  async onFormDataUpdate (val) {
    if (!val?.formData?.categoryName || !val?.inspection?.id) return

    const { categoryName, total, category } = val.formData
    const { inspection } = val
    const { categoryQualificationConfig } = this

    await this.updateCategoryToValidated(inspection)

    if (!inspection.status.isInSupervision && !inspection.status.isClosed && inspection?.inspectorQualification) {
      await this.inspectionInSupervision(inspection)
    }

    if (categoryName === 'Identificación') {
      const newScore = this.getScore(categoryQualificationConfig, category, inspection, categoryName)

      await this.updateCategory(categoryName, newScore, inspection, total, 1)

      this.formData = {
        categoryName: null,
        category: null,
        total: null,
        score: null,
      }
    }
  }

  findCategoryByName (categories, categoryName) {
    return categories.find(category => category.name === categoryName)
  }

  async setInspectionData (inspection) {
    const { categoryName, total, score } = this.formData

    // Obtener la categoría correspondiente en metadata y supervisorMetadata
    const categoryInMetadata = this.findCategoryByName(inspection.metadata.categories, categoryName)
    if (!categoryInMetadata) {
      throw new Error(`Categoría con nombre ${categoryName} no encontrada en metadata.`)
    }

    // Si supervisorMetadata no existe, crearlo
    if (!this.inspection.supervisorMetadata) {
      this.inspection.supervisorMetadata = _.cloneDeep(this.inspection.metadata)
    }

    let categoryInSupervisorMetadata = this.findCategoryByName(this.inspection.supervisorMetadata.categories, categoryName)

    if (!categoryInSupervisorMetadata) {
      categoryInSupervisorMetadata = _.cloneDeep(categoryInMetadata)

      // Actualizar los campos necesarios en supervisorMetadata antes de agregar
      categoryInSupervisorMetadata.validated = true
      categoryInSupervisorMetadata.supervisorCost = total
      categoryInSupervisorMetadata.score = score || categoryInMetadata.score

      // Ahora agregamos la categoría actualizada al arreglo
      this.inspection.supervisorMetadata.categories.push(categoryInSupervisorMetadata)
    } else {
      // Si la categoría ya existe, simplemente actualizamos sus campos
      categoryInSupervisorMetadata.validated = true
      categoryInSupervisorMetadata.supervisorCost = total
      categoryInSupervisorMetadata.score = score || categoryInMetadata.score
    }

    // Guardar los cambios
    return this.pushData({
      model: 'Inspection',
      fields: {
        id: inspection.id,
        supervisor_metadata: this.inspection.supervisorMetadata,
        id_supervisor: this.idEmployee,
      },
    })
  }

  async updateCategoryToValidated (inspection) {
    if (!inspection?.id) return null
    const { category, formData, categoryQualificationConfig } = this

    if (!formData?.categoryName) return null

    const keys = Object.keys(category)
    const cont = -1

    this.handleCategories(categoryQualificationConfig, keys, inspection)

    this.handlePanels(cont, keys)
    await this.viewInspectionStatus(inspection)

    const insp = await this.setInspectionData(inspection)
    this.setBackup({ inspection: this.inspection })

    this.formData = {
      categoryName: null,
      category: null,
      total: null,
      score: null,
    }

    this.closeForms()

    this.setInspection(insp)
  }

  @Debounce(1000)
  setInspection (inspection) {
    this.inspection = inspection
  }

  async inspectionInSupervision (inspection) {
    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'inspection' } } }, { status: { name: { _eq: 'supervision' } } }] },
    })
    await this.pushData({
      model: 'Inspection',
      fields: {
        id: inspection.id,
        id_process_status: status?.[0]?.id,
        id_supervisor: this.idEmployee,
      },
    })
  }

  getScore (categoryQualificationConfig, category, inspection, name) {
    if (!category?.id || !categoryQualificationConfig) return undefined

    const score = inspection.getCategoryScore(category.id)

    let configValue: number | undefined

    Object.entries(categoryQualificationConfig?.[name]?.scores).forEach(([key, value]) => {
      const config = value as Record<string, any>
      if (score >= config.min && (config.max === null || score <= config.max)) {
        configValue = config.value
      }
    })

    return configValue
  }

  openCard (val) {
    const category = this.categoryFixed[val.category]
    for (const key in this.cards) {
      if (key !== category) {
        this.cards[key] = false
      }
    }

    this.cards[category] = true
  }

  @Debounce(1000)
  handleCategories (config, keys, inspection) {
    let cont = -1
    const { categoryTarget, categoryScore } = this
    const categories = _.cloneDeep(inspection.supervisorMetadata?.categories)?.filter(Boolean)?.sort((a, b) => a.order - b.order) || []
    let someOver = false

    categories?.forEach(category => {
      if (config[category.name]?.over && categoryScore[categoryTarget[category.name]] && categoryScore[categoryTarget[category.name]] >= config[category.name]?.over) {
        someOver = true
        this.overCategory = category.name
      }
    })

    categories?.forEach((category, index) => {
      if (category.validated && !someOver) {
        cont = index + 1
        this.category[keys[index]] = true
      } else if (category?.done && someOver) {
        const pos = categoryTarget[category.name] === 'identifier' ? 0 : 1
        cont = index - pos
        this.category[keys[cont]] = true
      }
    })

    if (!someOver && this.overCategory) {
      this.overAlert.open = true
    }

    this.handlePanels(cont, keys)
  }

  handlePanels (cont, keys) {
    if (cont !== -1 && keys[cont] && this.form[keys[cont]].panels !== 0) {
      this.form[keys[cont]].panels = 0
    }
  }

  @Watch('form', { immediate: true, deep: true })
  @Debounce()
  onFormPanelChange () {
    this.formChanged = !this.formChanged
  }

  async viewInspectionStatus (inspection) {
    const { generation } = this
    if (!generation) {
      this.generation = await this.getAutoGeneration(this.inspection.auto)
      if (!this.generation) {
        this.generation = { attributes: this.inspection.auto?.version?.attributes }
      }
    }

    if (inspection?.amountNegotiation < 0) {
      this.negotiationAlert.open = true
    }
  }

  get negotiationNegative () {
    const { inspection } = this

    return inspection?.amountNegotiation < 0
  }

  get someActive () {
    const { cards } = this

    return Object.values(cards).some(card => card)
  }

  async getAutoGeneration (auto) {
    if (!auto?.generation?.id) return this.findAlternativesAttributes(auto)

    const generation = await this.fetchData({
      query: { name: 'fetch', params: { id: auto.generation.id }, model: 'Generation' },
    })

    if (generation?.attributes?.length) {
      return generation
    }

    return this.findAlternativesAttributes(auto)
  }

  async findAlternativesAttributes (auto) {
    const generation = plainToInstance(Generation, {})

    if (auto.version?.attributes?.length) {
      generation.attributes = auto.version.attributes
      return generation
    }

    const generations = await this.fetchData({
      query: { name: 'find', model: 'Generation' },
      filter: { version_year: { id_version: { _eq: auto.version.version.id }, id_year: { _eq: auto.version.year.id - 1 } } },
    })

    return generations[0]
  }

  get someCardOpen () {
    const { cards } = this
    return Object.values(cards).some(card => card)
  }

  confirmNegotiationAlert () {
    this.negotiationAlert.open = false
  }

  async confirmOver () {
    this.overAlert.open = false

    const idProcessStatus = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'inspection' } } }, { status: { name: { _eq: 'active' } } }] },
    })

    if (this.overCategory) {
      const pos = this.inspection.metadata?.categories?.findIndex(category => category.name === this.overCategory)
      this.inspection.metadata.categories[pos].validated = false
      this.inspection.metadata.categories[pos].progress = 0
    }
    await this.pushData({
      model: 'Inspection',
      fields: {
        id: this.inspection.id,
        id_process_status: idProcessStatus?.[0]?.id,
        metadata: {
          ...this.inspection.metadata,
        },
        id_supervisor: this.idEmployee,
      },
    })
    this.overCategory = null
    await this.close()
  }

  async updateCategory (categoryName, score, inspection, total = 0, order = null) {
    if (!score || !categoryName) return

    const pos = inspection.metadata?.categories?.findIndex(category => category.name === categoryName)

    if (!inspection?.metadata?.categories) {
      await this.pushData({
        model: 'Inspection',
        fields: {
          id: inspection.id,
          id_supervisor: this.idEmployee,
          supervisor_metadata: {
            categories: [
              {
                order,
                name: categoryName,
                score,
                progress: 100,
                supervisorCost: total,
              }],
          },
        },
      })
    } else if (pos === -1) {
      inspection.supervisorMetadata.categories.push({
        order,
        name: categoryName,
        score,
        progress: 100,
        supervisorCost: total,
      })
      await this.pushData({
        model: 'Inspection',
        fields: { id: inspection.id, metadata: { ...inspection.metadata } },
      })
    } else {
      inspection.supervisorMetadata.categories[pos].score = score
      inspection.supervisorMetadata.categories[pos].supervisorCost = total
      await this.pushData({
        model: 'Inspection',
        fields: { id: inspection.id, supervisor_metadata: { ...inspection.supervisorMetadata }, id_supervisor: this.idEmployee },
      })
    }
    return inspection
  }

  closeForms () {
    const keys = Object.keys(this.cards)

    keys.forEach(key => {
      this.cards[key] = false
    })
  }
  }
